import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/github/workspace/node_modules/gatsby-theme-docz/src/base/Layout.js";
import "../style-index.css";
import Image from "../../src/components/image";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>Events</h1>
    <p>
  {" "}
  <span>Administrators</span> <span>Coaches</span> <span>Entrepreneurs</span> <span>Mentors</span>
    </p>
    <p>{`Events page shows you all the meetings plan for the months. It is a great place to come together.`}</p>
    <h2 {...{
      "id": "event-types"
    }}>{`Event Types`}</h2>
    <p>{`There are 3 different events types:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Workshops (Group session):`}</strong>{` represented in blue, these are group sessions, workshops, or training.`}</li>
      <li parentName="ul"><strong parentName="li">{`Announcements:`}</strong>{` represented in red. These events are only seen by Administrators or Coaches that have planned announcements to be seen in the activity feed or Home page.`}</li>
      <li parentName="ul"><strong parentName="li">{`Mentoring/Coaching sessions:`}</strong>{` represented in yellow, these are the one to one sessions with a mentor or coach.`}</li>
    </ul>
    <h2 {...{
      "id": "calendar-view"
    }}>{`Calendar View`}</h2>
    <p>{`In the Calendar in the Events page you will find a filter where you can select programs, and what type of calendar you want to see.`}</p>
    <p>{`These are the filter options per user:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Entrepreneurs:`}</strong>{` can see calendars between different programs.`}</li>
      <li parentName="ul"><strong parentName="li">{`Mentors:`}</strong>{` can see calendars between different programs, and also see calendars based on the assigned startups.`}</li>
      <li parentName="ul"><strong parentName="li">{`Coaches:`}</strong>{` can see calendars between different programs, and also see calendars based on the assigned startups.`}</li>
      <li parentName="ul"><strong parentName="li">{`Administrators:`}</strong>{` can see calendars between different programs, see calendars based on the assigned startups, and all the events on a program.`}</li>
    </ul>
    <h2 {...{
      "id": "new-workshop"
    }}>{`New Workshop`}</h2>
    <p>{`Creating events allows the community to be engaged. You can run workshops or group sessions so that your community comes together.`}</p>
    <p>{`These events can be created by Administrators and Coaches.`}</p>
    <p>{`Browse to the Events page by clicking in Events on the left menu.
In the Events page you can create a new Event in two different ways, by clicking in New Event or on the date that you want to book the Event.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Type:`}</strong>{` Workshop`}</li>
      <li parentName="ul"><strong parentName="li">{`Program:`}</strong>{` in which program this event will be visible.`}</li>
      <li parentName="ul"><strong parentName="li">{`Subject:`}</strong>{` Event Title.`}</li>
      <li parentName="ul"><strong parentName="li">{`Date/Time:`}</strong>{` select the date and time for your new event. In the case that you chosen already the date then you just need to define the time.`}</li>
      <li parentName="ul"><strong parentName="li">{`Duration:`}</strong>{` how long do you want the event duration to be.`}</li>
      <li parentName="ul"><strong parentName="li">{`Topic:`}</strong>{` this is the tag that you identify later engagement per user. It is very important to have a clear tagging here.`}</li>
      <li parentName="ul"><strong parentName="li">{`Host E-Mail:`}</strong>{` the host could be an external attendee tha does not have access to the platform.`}</li>
      <li parentName="ul"><strong parentName="li">{`Host Name:`}</strong>{` name of the event host.`}</li>
      <li parentName="ul"><strong parentName="li">{`Invites:`}</strong>{` people that are invited to attend the event. You will have a drop-down where you can select from.`}</li>
      <li parentName="ul"><strong parentName="li">{`Location:`}</strong>{` is this a physical location or a virtual meeting, put the address or link to the meeting.`}</li>
      <li parentName="ul"><strong parentName="li">{`Agenda:`}</strong>{` here is the description of the meeting.`}</li>
    </ul>
    <p>{`The entries with (`}{`*`}{`) are mandatory to be completed.`}</p>
    <Image src="https://res.cloudinary.com/dnuwcxti0/image/upload/w_800/v1610583672/teamcoaches/documentation/NewEvents_obpbi5.gif" alt="Workshop" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      